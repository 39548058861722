<script setup lang="ts">
import { Field_Group } from "../../../../gql/types/types";
import FormieField from "../FormieField.vue";
import { computed, markRaw, onMounted, ref, toRefs, watch, watchEffect } from "vue";

const props = defineProps({
  field: {
    type: Object as () => Field_Group,
    required: true
  },
  formValues:{
    type:Object,
    required:true
  }
})
const {field, formValues} = toRefs(props)


const flattenFormValues = (values: any) => {
  let fieldValues: { [key: string]: any } = {};
  const collect = (obj: any) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key])) {
          if (!fieldValues[key]) {
            fieldValues[key] = [];
          }
          fieldValues[key] = fieldValues[key].concat(obj[key]);
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          collect(obj[key]);
        } else {
          if (!fieldValues[key]) {
            fieldValues[key] = obj[key];
          } else if (Array.isArray(fieldValues[key])) {
            fieldValues[key].push(obj[key]);
          } else {
            fieldValues[key] = [fieldValues[key], obj[key]];
          }
        }
      }
    }
  };
  collect(values);
  return fieldValues;
};

const conditionValue = ref('0')
const flatValues = computed(()=>{
  return flattenFormValues(formValues.value)
})
const conditions = computed(() => {
  return JSON.parse(field.value.conditions).conditions;
});

const conditionField = computed(()=>{
  return conditions.value[0].field.replace(/{field:|}/g, '')
})
const library = markRaw({
  FormieField:FormieField
})

const groupSchema = computed(() => {
  return [
    {
      $el: 'div',
      name: field.value.handle,
      if:'$conditionValue == 1',
      $formkit: 'group',
      children:[{

        $cmp:'FormieField',
        for:['nestedField','key','$field.fields'],
        props:{
          field:'$nestedField',
          formValues:'$formValues'
        }
      }]
    }
    
  ];

});

watchEffect(() => {
  const flattenedValues = flattenFormValues(formValues);
  conditionValue.value = flattenedValues[conditionField.value][0];
})
</script>

<template>
    <FormKitSchema :schema="groupSchema" :library="library" :data="{field:field,formValues:formValues, conditionValue:conditionValue}" />
</template>
<style scoped lang="scss">

</style>
